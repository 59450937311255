import { _ } from 'core-js'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/signin'
    },
    {
      path: '/signin',
      name: 'Signin',
      component: () => import('@/views/Signin')
    },

    /// Profile
    {
      path: '/profile',
      redirect: '/profile/profile',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/profile/Profile')
        }
      ]
    },
    /// Overview
    {
      path: '/overview',
      redirect: '/overview/stats',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Stats',
          path: 'stats',
          component: () => import('@/views/dashboard/overview/Stats')
        },
        {
          name: 'DDPay Orders',
          path: 'ddpay-orders',
          component: () => import('@/views/dashboard/overview/DDPayOrders'),
          meta: { clearance: 'superadmin' }
        },
        {
          name: 'Orders',
          path: 'orders',
          component: () => import('@/views/dashboard/overview/Orders')
        }
      ]
    },
    /// Administration
    {
      path: '/admin',
      redirect: '/admin/merchants',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Organizations',
          path: 'orgs',
          component: () => import('@/views/dashboard/administration/Organizations'),
          meta: { clearance: 'superadmin' }
        },
        {
          name: 'Merchants',
          path: 'merchants',
          component: () => import('@/views/dashboard/administration/Merchants')
        },
        {
          name: 'Merchant Settings',
          path: 'merchant-settings',
          component: () => import('@/views/dashboard/administration/MerchantSettings')
        },
        {
          name: 'PaymentServices',
          path: 'payment-services',
          component: () => import('@/views/dashboard/administration/PaymentServices'),
          meta: { clearance: 'admin' }
        },
        {
          name: 'Administrators',
          path: 'administrators',
          component: () => import('@/views/dashboard/administration/Administrators'),
          meta: { clearance: 'superadmin' }
        }
      ]
    },
    /// Value-added Services
    {
      path: '/vas',
      redirect: '/vas/qpoints',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'QPoints Exchanges',
          path: 'qpoints',
          component: () => import('@/views/dashboard/vas/QPointsExchanges')
        },
        {
          name: 'Donations',
          path: 'donations',
          component: () => import('@/views/dashboard/vas/Donations')
        },
        {
          name: 'Member-Discount Records',
          path: 'member-discount-records',
          component: () => import('@/views/dashboard/vas/MemberDiscountRecords')
        }
      ]
    },
    /// ACH Service
    {
      path: '/ach',
      redirect: '/ach/beneficiaries',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Beneficiaries',
          path: 'beneficiaries',
          component: () => import('@/views/dashboard/ach-service/Beneficiaries'),
          meta: { clearance: 'superadmin' }
        },
        {
          name: 'Transfer',
          path: 'transfer',
          component: () => import('@/views/dashboard/ach-service/Transfer'),
          meta: { clearance: 'superadmin' }
        },
        {
          name: 'Remittances',
          path: 'remittances',
          component: () => import('@/views/dashboard/ach-service/Remittances'),
          meta: { clearance: 'superadmin' }
        },
        {
          name: 'Batches',
          path: 'batches',
          component: () => import('@/views/dashboard/ach-service/Batches'),
          meta: { clearance: 'superadmin' }
        },
        // {
        //   name: 'Invoices',
        //   path: 'invoices',
        //   component: () => import('@/views/dashboard/ach-service/Invoices'),
        //   meta: { clearance: 'superadmin' }
        // },
        // {
        //   name: '回檔',
        //   path: 'transfer-back',
        //   component: () => import('@/views/dashboard/ach-service/TransferBack'),
        //   meta: { clearance: 'superadmin' }
        // }
      ]
    },


    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },

    /// CardPay
    {
      path: '/cardpay',
      redirect: '/cardpay/merchants',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Cardpay Stores',
          path: 'merchants',
          component: () => import('@/views/dashboard/cardpay/Merchants')
        },
        {
          name: 'Cardpay Orders',
          path: 'orders',
          component: () => import('@/views/dashboard/cardpay/Orders')
        },
        {
          name: 'Cardpay Surcharges',
          path: 'surcharges',
          component: () => import('@/views/dashboard/cardpay/Surcharges')
        }
      ]
    },
    /// LoginManagement
    {
      path: '/loginmanagement',
      redirect: '/loginmanagement/LoginRecords',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'loginrecords',
          path: 'LoginRecords',
          component: () => import('@/views/dashboard/loginmanagement/LoginRecords')
        }
      ]
    },
    // {
    //   path: '/',
    //   component: () => import('@/views/dashboard/Index'),
    //   children: [
    //     // Dashboard
    //     {
    //       name: 'Dashboard',
    //       path: '',
    //       component: () => import('@/views/dashboard/Dashboard'),
    //     },
    //     // Pages
    //     {
    //       name: 'RTL',
    //       path: 'pages/rtl',
    //       component: () => import('@/views/dashboard/pages/Rtl'),
    //     },
    //     {
    //       name: 'User Profile',
    //       path: 'pages/user',
    //       component: () => import('@/views/dashboard/pages/UserProfile'),
    //     },
    //     {
    //       name: 'Timeline',
    //       path: 'pages/timeline',
    //       component: () => import('@/views/dashboard/pages/Timeline'),
    //     },
    //     // Components
    //     {
    //       name: 'Buttons',
    //       path: 'components/buttons',
    //       component: () => import('@/views/dashboard/component/Buttons'),
    //     },
    //     {
    //       name: 'Grid System',
    //       path: 'components/grid-system',
    //       component: () => import('@/views/dashboard/component/Grid'),
    //     },
    //     {
    //       name: 'Tabs',
    //       path: 'components/tabs',
    //       component: () => import('@/views/dashboard/component/Tabs'),
    //     },
    //     {
    //       name: 'Notifications',
    //       path: 'components/notifications',
    //       component: () => import('@/views/dashboard/component/Notifications'),
    //     },
    //     {
    //       name: 'Icons',
    //       path: 'components/icons',
    //       component: () => import('@/views/dashboard/component/Icons'),
    //     },
    //     {
    //       name: 'Typography',
    //       path: 'components/typography',
    //       component: () => import('@/views/dashboard/component/Typography'),
    //     },
    //     // Forms
    //     {
    //       name: 'Regular Forms',
    //       path: 'forms/regular',
    //       component: () => import('@/views/dashboard/forms/RegularForms'),
    //     },
    //     {
    //       name: 'Extended Forms',
    //       path: 'forms/extended',
    //       component: () => import('@/views/dashboard/forms/ExtendedForms'),
    //     },
    //     {
    //       name: 'Validation Forms',
    //       path: 'forms/validation',
    //       component: () => import('@/views/dashboard/forms/ValidationForms'),
    //     },
    //     {
    //       name: 'Wizard',
    //       path: 'forms/wizard',
    //       component: () => import('@/views/dashboard/forms/Wizard'),
    //     },
    //     // Tables
    //     {
    //       name: 'Regular Tables',
    //       path: 'tables/regular-tables',
    //       component: () => import('@/views/dashboard/tables/RegularTables'),
    //     },
    //     {
    //       name: 'Extended Tables',
    //       path: 'tables/extended-tables',
    //       component: () => import('@/views/dashboard/tables/ExtendedTables'),
    //     },
    //     {
    //       name: 'Data Tabels',
    //       path: 'tables/data-tables',
    //       component: () => import('@/views/dashboard/tables/DataTables'),
    //     },
    //     // Maps
    //     {
    //       name: 'Google Maps',
    //       path: 'maps/google-maps',
    //       component: () => import('@/views/dashboard/maps/GoogleMaps'),
    //     },
    //     {
    //       name: 'Full Screen Map',
    //       path: 'maps/full-screen-map',
    //       component: () => import('@/views/dashboard/maps/FullScreenMap'),
    //     },
    //     // Root level
    //     {
    //       name: 'Widgets',
    //       path: 'widgets',
    //       component: () => import('@/views/dashboard/Widgets'),
    //     },
    //     {
    //       name: 'Charts',
    //       path: 'charts',
    //       component: () => import('@/views/dashboard/Charts'),
    //     },
    //     {
    //       name: 'Calendar',
    //       path: 'calendar',
    //       component: () => import('@/views/dashboard/Calendar'),
    //     },
    //   ],
    // },
    // {
    //   path: '*',
    //   component: () => import('@/views/pages/Index'),
    //   children: [
    //     {
    //       name: '404 Error',
    //       path: '',
    //       component: () => import('@/views/pages/Error'),
    //     },
    //   ],
    // },
  ],
})
